import styles from './HeroSection.module.scss'

const HeroSection = () => {


    return (
        <section className={styles.heroSection}>
            <div className={styles.textContainer}>
                <div className={styles.title}>
                    Ready made data dahsboards
                    <br />
                    for your enterprise customers.
                </div>

                <div className={styles.subtitle}>
                    BOOM.
                </div>
            </div>
        </section>
    );
}

export default HeroSection;