import React from 'react';
import './App.css';
import Header from './components/header/header';
import HeroSection from './components/heroSection/HeroSection';
import SecondSection from './components/SecondSection/SecondSection';

function App() {
  return (
    <>
      <Header />
      <HeroSection />
      <SecondSection />
    </>
  );
}

export default App;
