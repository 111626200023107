import styles from './buttons.module.scss';

export enum ButtonStyles {
    PRIMARY = "primary",
    SECONDARY = "secondary"
}

type Props = {
    type: ButtonStyles;
    children: JSX.Element
}


const Buttons = ({ type, children }: Props) => {


    return (
        <button className={styles[type]}>
            {children}
        </button>
    );
}

export default Buttons;