import styles from './SecondSection.module.scss';


const SecondSection = () => {


    return (
        <div className={styles.main}>
            <div className={styles.gridOne}>
                <div className={styles.columns}></div>
                <div className={styles.columns}></div>
            </div>
            <div className={styles.gridTwo}>
                <div className={styles.textSection}>
                    <div className={styles.title}>
                        Hold up.
                    </div>
                    <div className={styles.subtitle}>
                        Are you still building
                        <br />
                        reports for your
                        <br />
                        customers yourself?
                    </div>

                </div>

                <div className={styles.bottomTitleContainer}>
                    <div className={styles.title}>
                        THERE'S A MUCH EASIER WAY.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SecondSection;