import styles from './header.module.scss';
import MeritoDarkLogo from '../../assets/images/MeritoLogoDark.svg';
import Buttons, { ButtonStyles } from '../buttons/Buttons';

const Header = () => {


    return (
        <nav className={styles.header}>
            <div className={styles.brandContainer}>
                <img src={MeritoDarkLogo} />
            </div>

            <div className={styles.rightContainer}>
                <Buttons type={ButtonStyles.PRIMARY}>
                    <div className={styles.btnContainer}>
                        Sign in
                    </div>
                </Buttons>
                <Buttons type={ButtonStyles.PRIMARY}>
                    <div className={styles.btnContainer}>
                        Contact
                    </div>
                </Buttons>
            </div>
        </nav>
    );
}

export default Header;